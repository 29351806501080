import React, { useState, useEffect } from "react";
import logo from "../images/dealersyslongtransparent.png";
import digitalLeader from "../images/dealerlogo.png";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Link } from "gatsby";

const Navbar = ({ location }) => {
  // const [colorChange, setColorchange] = useState(false);
  // useEffect(() => {
  //   const changeNavbarColor = () => {
  //     if (window.scrollY >= 10) {
  //       setColorchange(true);
  //     } else {
  //       setColorchange(false);
  //     }
  //   };
  //   window.addEventListener("scroll", changeNavbarColor);
  // }, []);

  return (
    <div className="w-full ">
      <div className="max-w-7xl relative top-0 z-[1000] mx-auto xl:px-10 px-8 py-8 shadow-sm">
        <div className="flex justify-between">
          <div className="flex cursor-pointer">
            <Link to="/">
              <img
                src={
                  (location !== undefined &&
                    location.pathname !== undefined &&
                    location.pathname === "/digital-dealer") ||
                  location.pathname === "/contest-rules"
                    ? digitalLeader
                    : logo
                }
                alt=""
                className="w-48"
              />
              {/* DEALERSYS */}
            </Link>
          </div>
          <div className="flex self-center">
            <ul className="flex ">
              <li className="relative solution_manu">
                <div className="flex ">
                  <Link to="/solutions">
                    <span className="px-6 text-sm capitalize transition-all NexaTextDemoLight ">
                      SOLUTIONS
                    </span>
                  </Link>
                  {/* <span className="flex self-center -ml-4">
                    <ChevronDownIcon className={"w-6 h-6 r-5"} />
                  </span> */}
                </div>
                {/* <li className="flex absolute submenu left-0 right-0 min-w-[165px] bg-white shadow-md py-3.5 px-1 rounded-md">
                  <Link to="/pricing">
                    <span className="px-6 text-sm capitalize transition-all NexaTextDemoLight ">
                      UCaaS PRICING
                    </span>
                  </Link>
                </li> */}
              </li>

              <li>
                <Link to="/providers">
                  <span className="px-6 text-sm capitalize transition-all NexaTextDemoLight ">
                    PROVIDERS
                  </span>
                </Link>
              </li>
              <li>
                <Link to="/nada">
                  <span className="px-6 text-sm uppercase transition-all NexaTextDemoLight ">
                    NADA
                  </span>
                </Link>
              </li>
              <li>
                <Link to="/about">
                  <span className="px-6 text-sm capitalize transition-all NexaTextDemoLight ">
                    ABOUT
                  </span>
                </Link>
              </li>
              <li>
                <AnchorLink to="/become-a-partner">
                  <span className="px-6 text-sm capitalize transition-all NexaTextDemoLight ">
                    BECOME A PARTNER
                  </span>
                </AnchorLink>{" "}
              </li>
              <li>
                <Link to="/contact-us">
                  <span className="px-8 py-3 ml-2 text-sm text-white capitalize transition-all bg-black rounded-full hover:shadow-lg NexaTextDemoLight">
                    Contact Us
                  </span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
