import React from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";
import MobileMenu from "./MobileMenu";
const Layout = ({ children,location }) => {
  return (
    <div>
      <div className="hidden lg:block">
        <Navbar location={location}/>
      </div>
      <div className="block lg:hidden">
        <MobileMenu location={location}/>
      </div>
      {children}
      {/* <Footer /> */}
    </div>
  );
};

export default Layout;
